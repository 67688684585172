import React from "react"
import { Link, graphql } from "gatsby"

import Button from "../components/button"
import SEO from "../components/seo"
import Hero from "../components/hero"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const pic = data.file.childImageSharp.fluid.src
    return (
      <>
        <SEO title="404: Not Found" />
        <Hero imageSrc={pic}
        minHeight="100vh">
          <h1>Page Not Found</h1>
          <Link to="/">
            <Button>Go back home</Button>
          </Link>
        </Hero>
      </>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    file(name: {eq: "404-pic"}) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            src
          }
        }
      }
    
  }
`
